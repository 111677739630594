import "jquery";
import "slick-carousel";

const showMenuClass = '--show-menu';
const menuNoHoverClass = '--no-hover';
const showPopUp = '--show-popup';
const bodyPopUp = '--body-popup';
const scrollTopDisplayTriggerFactor = .3;
const accordionOpenClass = "--show";

function rot(message) {
    return message.replace(/[a-z]/gi, letter => String.fromCharCode(letter.charCodeAt(0) + (letter.toLowerCase() <= 'm' ? 13 : -13)));
}

$(() => {
    const $body = $('body');
    const $header = $('#header');

    $('.toggler').on('click', function (event) {
        event.preventDefault();
        $(this).addClass(menuNoHoverClass);
        $header.toggleClass(showMenuClass);
    }).on('mouseleave blur', function () {
        $(this).removeClass(menuNoHoverClass);
    });

    $('#to-top').on('click', 'a', function (event) {
        if ($body.hasClass('--scrolled')) {
            event.preventDefault();
            window.scrollTo({
                top: 0, behavior: "smooth",
            });
        }
    })

    const emailObfuscationRegex = new RegExp(/obfmail:([^@\s]+@[^\s'"]+)/i);
    $('a[href^="obfmail:"]').on('click', function (event) {
        event.preventDefault();
        const matches = emailObfuscationRegex.exec($(this).attr('href'));
        if (matches) window.location.href = 'mailto:' + rot([...(matches[1])].reverse().join(''));
    });


//Slick-sliders

    $('.slider').each(function () {
        const $slider = $(this);

        const dots = !$slider.hasClass('--no-dots');

        $slider.slick({
            infinite: true, autoplay: true, rows: 0, arrows: false, dots,
        });
    });
    $('.slider-popup').each(function () {
        const $slider = $(this);
        $slider.slick({
            infinite: true, autoplay: false, rows: 0, arrows: false, dots: false,
        });
    });

    $('.preview-popup, .masthead-slider')
        .on('click', '.arrow-next', function (event) {
            $(event.delegateTarget).find('.slider,.slider-popup').slick('slickNext');
        })
        .on('click', '.arrow-prev', function (event) {
            $(event.delegateTarget).find('.slider,.slider-popup').slick('slickPrev');
        });

    function closePopUp() {
        const $popup = $('.preview-popup-wrapper');
        $popup.addClass('--showing-popup');
        $popup.removeClass(showPopUp);
        $body.removeClass(bodyPopUp);
        setTimeout(() => {
            $popup.removeClass('--showing-popup');
        }, 300);
    }

    $('.highlight-preview').on('click', function (event) {
        event.preventDefault();
        const $popup = $(this).siblings('.preview-popup-wrapper');
        $popup.addClass('--showing-popup');
        $popup.addClass(showPopUp);
        $body.addClass(bodyPopUp);
        setTimeout(() => {
            $popup.removeClass('--showing-popup');
        }, 300);
    })

    $('.preview-popup-wrapper').on('click', function (event) {
        if (event.target === event.currentTarget) {
            closePopUp();
        }
    });

    $('.out').on('click', function () {
        closePopUp();
    });

    //event tabs

    $('.event-tabs').on('click', '.tab', function (event) {
        event.preventDefault()
        const $tag = $(event.target);
        const $tabs = $(event.delegateTarget);

        const id = $tag.attr('data-tab');

        $tabs.find('.tab').removeClass('--is-selected');
        $tag.addClass('--is-selected')

        $tabs.find('.information-tab').removeClass('--show-information');
        $tabs.find('.information-tab#' + id).addClass('--show-information');
    });

    // ABout-Us Accordion

    $(".accordion-trigger").each(function () {
        $(this).on("click", function (event) {
            event.preventDefault();
            const $trigger = $(this);
            const $content = $($trigger.attr("href"));
            if ($content.length) {
                if ($content.hasClass(accordionOpenClass)) {
                    $content.css("max-height", "");
                    $content.removeClass(accordionOpenClass);
                    $trigger.removeClass(accordionOpenClass);
                } else {
                    $content.css("max-height", $content.children(".accordion-content").outerHeight() + "px");
                    $content.addClass(accordionOpenClass);
                    $trigger.addClass(accordionOpenClass);
                }
            }
        });
    });
});
